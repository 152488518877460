<i18n lang="yaml" locale="de">
  user.config: "Konfiguration"
  user.userConfig: "Benutzer"
  user.logout: "Ausloggen"
  app.status: "App-Status"
  app.report: "Support-Ticket"
  loading.PrepareData: "Daten werden vorbereitet..."
  meeting.mode.title: "Meetingmodus einschalten"
  meeting.mode.customer: "Kundenmeeting"
</i18n>
<i18n lang="yaml" locale="en">
  user.config: "Configuration"
  user.userConfig: "Users"
  user.logout: "Logout"
  app.status: "App status"
  app.report: "Support ticket"
  loading.PrepareData: "Loading data for you..."
  meeting.mode.title: "Toggle meeting mode"
  meeting.mode.customer: "Customer meeting"
  loading.slogans_0: "BAIT up your life"
  loading.slogans_1: "No BAIT no fun"
  loading.slogans_2: "UnBAITable"
  loading.slogans_3: "Just BAIT it"
  loading.slogans_4: "Never gonna give BAIT up, never gonna let BAIT down"
  loading.slogans_5: "It's the final BAITdown"
  loading.slogans_6: "I shot the Excel, but I did not shoot the BAIT system"
  loading.slogans_7: "We will BAIT you"
  loading.slogans_8: "BAIT is BAIT"
  loading.slogans_9: "Living on a BAIT"
  loading.slogans_10: "BAIT habits"
  loading.slogans_11: "Back in BAIT"
  loading.slogans_12: "BAIT, BAIT, Baby"
  loading.slogans_13 : "Ace of BAITs"
  loading.slogans_14 : "It's a BAIT day"
  loading.slogans_15 : "Always look on the BAIT side of life"
  loading.slogans_16 : "BAIT up your life"
  loading.slogans_17 : "You are upBAITed"
  loading.slogans_18 : "Just a little BAIT"
  loading.slogans_19: "BAIT this!"
  loading.slogans_20 : "BAIT in and find out."
  loading.slogans_21 : "Bits and BAITs"
  loading.slogans_22 : "It's BAITastic"
  loading.slogans_23 : "ClickBAIT"
  loading.slogans_24 : "BAIT to life, BAIT to reality"

  loading.slogans.title: "Have a suggestion? Send it to pstaehle"






</i18n>
<template>

    <b-overlay 
      :show="$store.getters.loadScreen"
      opacity="1"
      variant="secondary"
      no-center
    id="app" 
    :class="$store.getters.modes" 
    class="d-flex flex-column vh-100"
      >
      <template #overlay>
        <div class="d-block position-absolute w-100 h-100 overflow-hidden gradient" :class="{'animated-gradient' : $store.getters.isAuthenticated }" style="z-index: 11;"></div>
        <div class="d-block position-absolute" style="width: 430px;top:50%;left:50%;transform: translate(-50%, -50%);z-index: 12;">
          <div class="d-flex justify-content-center align-items-center">
            <img :src="iconUrl" class="d-block m-auto"  height="100px" />
            <img :src="iconUrlAtera" class="d-block m-auto ml-2"  height="80px" />
          </div>
          <template v-if="$store.getters.isAuthenticated">
                
            <b-progress :max="$store.getters.loadingModules" :value="$store.getters.loadedModules" variant="secondary" class="flex-grow-1 mt-3"/>
            
            <div :title="$t('loading.slogans.title')" class="mt-4 h3 text-center font-weight-bold font-italic text-white">{{ $t(loadingSlogan) }}</div>
          </template>
          <template v-else>
            <dynamic-loader
                  module="user"
                  widget="loginComponent" />
            
            <div class="text-black-50 pr-2 text-right" v-b-modal="$id('impressum')">Impressum & Datenschutz</div>
            
          </template>
        </div>
      </template>
    <header id="app-header">
      <b-navbar variant="danger" type="dark" class="py-0">
          <b-navbar-brand style="width: 150px;" class="d-flex flex-nowrap justify-content-center align-items-center">
            <img :src="iconUrl" class="d-inline-block" height="20px" />
            <img :src="iconUrlAtera" class="d-inline-block ml-1" height="15px" />

            <span class="font-weight-bolder h4 ml-2 my-auto">BAIT</span>
          </b-navbar-brand>
          <b-nav-text class="flex-grow-1 mr-1 ownerHeaderDisplay">
            <dynamic-loader
              module="owner"
              class="app-header--owner"
              widget="Selector"
              overflow
              :customProperties="{ global: true }"
            >
            </dynamic-loader>
          </b-nav-text>
          <b-nav-text style="width: 200px;" class="m-auto px-1">
            <base-search />
          </b-nav-text>
           <b-nav-text class="ml-auto d-flex justify-content-between">
          <dynamic-loader widget="Mailbox" module="relation" :customProperties="{ card: true }"/>
          <base-postits />
          <!-- Replace dropdown with toggle for now -->

          <b-form-checkbox 
            button 
            size="lg"
            :title="$t('meeting.mode.customer')"
            :button-variant="inMeeting ? 'customer' : 'primary'"
            @input="meetingModesUpdate($event)"
            
            >
            <b-icon-people-fill />
          </b-form-checkbox>

          <!--
          <b-dropdown 
            id="dropdown-dropright" 
            right 
            no-caret 
            :variant="inMeeting ? 'customer' : 'primary'" 
            :title="$t('meeting.mode.title')"
            boundary="viewport" 
            toggle-class="py-1" 
            class="m-0">
            <template #button-content>
                <span class="h4"><b-icon-people-fill /></span>
              </template>
            <b-dropdown-form >
              
                <b-form-checkbox-group
                  class="text-nowrap"
                  :checked="selectedModes"
                  :options="modeOptions"
                  @input="meetingModesUpdate($event)"
                  switches
                  stacked
                ></b-form-checkbox-group>
            
            </b-dropdown-form>
          </b-dropdown> -->
          <!--<dynamic-loader widget="PostIts" module="user" />-->
          <b-dropdown variant="primary" right no-caret>
            <template #button-content>
              <span class="h4"><b-icon-gear-fill /></span>
            </template>
            <b-dropdown-item to="/myConfig"><span class="text-dark">{{ $t('user.config') }}</span></b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item to="/appInfo"><span class="text-dark">{{ $t('app.status') }}</span></b-dropdown-item>
            <b-dropdown-item v-b-modal="$id('bugreportform')"><span class="text-dark">{{ $t('app.report') }}</span></b-dropdown-item>
            <b-dropdown-divider />            
            <b-dropdown-item v-for="(lang, i) in $store.getters.languages"  class=""
                :key="`Lang${i}`" @click="$root.$i18n.locale=lang">
                <div class="d-flex">
                  <span class="text-dark"><b-icon :icon="$root.$i18n.locale==lang ? 'check2' : 'blank'" />{{ lang }}</span>
                </div>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item v-for="cur in $currency.acceptedCurrency"  class=""
                :key="cur" @click="$currency.setCurrency(cur)">
                <div class="d-flex">
                  <span class="text-dark"><b-icon :icon="$currency.getDisplayCurrency() == cur ? 'check2' : 'blank'" />{{ cur }}</span>
                </div>
            </b-dropdown-item>
            <b-dropdown-divider />

            <b-dropdown-item v-b-modal="$id('impressum')"><span class="text-dark">Impressum & Datenschutz</span></b-dropdown-item>
            <b-dropdown-item @click="$store.dispatch('logout')"><span class="text-dark">{{ $t('user.logout') }}</span></b-dropdown-item>
          </b-dropdown>
           </b-nav-text>
      </b-navbar>
      
    </header>    
    <main id="app-body" class="flex-grow-1">
      <div id="nav-sidebar-left" class="sidebar-width">
        <div class="d-flex py-1">
          <dynamic-loader
            module="task"
            widget="Creator"
            class="m-1"
            :customProperties="{ size: 'sm', templateOverrides: { categories: [] } }"
          ><b-icon-plus /></dynamic-loader>
        </div>
       <!--sidebar-->
       <side-bar></side-bar>
      </div>
      <div id="app-grid-wrapper" class="d-flex">
        <b-overlay :show="$store.getters.isNavigating" class="d-flex flex-grow-1">
          <grid-viewer id="app-body-content" class="overflow-hidden"></grid-viewer>
        </b-overlay>
      </div>
      <router-view />
    </main>
    <footer id="status-bar" 
      class="d-flex pl-2 pb-1 justify-content-start overflow-hidden"
      :class="inMeeting ? 'alert-customer' :'bg-light'"
      >
      <div class="d-flex flex-column align-items-start sidebar-width" v-b-modal="$id('whatsnew')">
        <span>&copy; BTS GmbH</span>
        <b-badge variant="light" >{{ appVersion }}</b-badge>
      </div>      
      <div class="monitor-container ml-3">
        <portal-target name="main-status-target" class="d-flex flex-nowrap" multiple>
        </portal-target>
        
      </div>
      <div class="d-flex ml-auto">
        
      </div>
    </footer>
    <sse-connector topic="/updates"></sse-connector>
    <b-modal :id="$id('bugreportform')" hide-footer>
      <dynamic-loader
        module="feedback"
        widget="Creator"
        :customProperties="{modal: $id('bugreportform')}"
      ></dynamic-loader>
    </b-modal>

    <b-modal :id="$id('whatsnew')" hide-header ok-only size="lg" body-class="p-0">
      <div v-html="whatnewHtml"></div>
    </b-modal>
    
    <b-modal :id="$id('impressum')" lazy size="lg" hide-footer>
      <impressum />
    </b-modal>

    <portal-target name="base-date-picker" class="d-block h-0 w-0" multiple>
      </portal-target>

    </b-overlay>
</template>

<style lang="scss">
@import "src/style/globalStyles";

.animatedIcon {
  animation-duration: 3s;
  animation-name: rotateY;
  animation-iteration-count: infinite;

}

.gradient {
  background: linear-gradient(
    -42deg, 
    var(--primary) 0%,
    var(--primary) 25%,
    #03767b 50%,
    var(--primary) 75%,
    var(--primary) 100%);
  background-size: 300% 300%;
}

.animated-gradient {
  animation: gradientAnimation 30s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}


@keyframes rotateY {
  from {
  transform: rotate3d(0, 1, 0, 0deg);
  }
  
  50% {
  transform: rotate3d(0, 1, 0, 180deg);
    
  }

  to {
  transform: rotate3d(0, 1, 0, 360deg);
  }
 }

.ownerHeaderDisplay {
  max-width: calc(100% - 330px);
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.nav-sidebar--link {
  padding-left: 5px;

  &.sidebar--link-active {
    background-color: $hover-color;
  }
}

.nav-sidebar--link--active {  
  @extend .alert-primary;
}

.nav-sidebar--link:hover {
  background-color: $hover-color;
}


.router-link-active {
  @extend .alert-primary;
}

#app.offline {
  @extend .border, .border-danger;
  border-width: 10px !important;
}
#app.meeting {
  @extend .border, .border-warning;
  border-width: 10px !important;
}


#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: auto;
  margin: auto;
  background-color: $light;
}

#app-body {
  display: flex;
  height: calc(100vh - 101px);
  max-height: calc(100vh - 101px);
}


#app-body-content {
  flex-grow: 1;
}

#app-grid-wrapper {
  flex-grow: 1;
}

#nav-sidebar-left {  
  border-right: 1px solid darken($light,5%);
  padding-right: 1px;
  background-color: $light;
  max-height: 100%;
  height: 100%;
  overflow: scroll;

  .nav-sidebar--link {
    a {
      display: block;
      padding: 5px;
      padding-left: 10px;
      text-decoration: none;
      color: $dark;

      &.router-link-exact-active {
        color: $light;
        background-color: $primary;
      }
    }
  }
}

.text-bold {
  font-weight: 500;
}

.form-group > label + div {
  @extend .w-100;
}

.zindex-21 {
  z-index: 21;
  position: relative;
}

</style>


<script>
import "vue-select/dist/vue-select.css";
import appVersionString from '!raw-loader!@/assets/version.info'
import { routes, groups } from "@/assets/Routes.js";
import DynamicLoader from './components/DynamicLoader.vue';
import SideBar from './components/sideBar.vue';
import BaseSearch from './components/BaseSearch/BaseSearch.vue'
const _ = require('lodash')

const config = require('@/configuration.json');

export default {
  components: { DynamicLoader, BaseSearch, SideBar},
  data() {
    return {     
      languages: ["de", "en"],
      appVersion: appVersionString.replace(/\s/g,''),

      iconUrl : config.baseUrl + "img/logo.png",
      iconUrlAtera : config.baseUrl + "img/atera.svg",

      whatnewHtml: "",
      loadingSlogan: 'loading.slogans_'+(Math.round(Math.random()*24)),
    };
  },
  methods: {
    meetingModesUpdate: function(ev) {
      if(ev) {
        this.$store.dispatch('setMeetingModes',['customer'])         
      } else {
        this.$store.dispatch('setMeetingModes',[])
      }
    },
  },
  computed: {  
       modeOptions: function(vm) {
         return [
          { text: vm.$t('meeting.mode.customer'), value: 'customer' },        
        ]
      },
      selectedModes: function(vm) {
        return vm.$store.getters.getMeetingModes ?? []
      },    
      inMeeting: function(vm) {
        return vm.selectedModes.length > 0
      }
  },
  mounted() {
    //check for cookie 
    let cookie = document.cookie.split('; ').find(row => row.startsWith('lastAppVersion='))
    let lastAppVersion = ""
    if(!!cookie ) {
      lastAppVersion = (cookie.split('=')[1] ?? "")
    }


    fetch(config.baseUrl + '/whatsnew/whatsnew.html').then(res => {
      res.text().then(txt => {
        this.whatnewHtml = txt.replace(/src="([^"]*)"/g,new RegExp('src="'+config.baseUrl +'/whatsnew$1"'))      
      })
      
    })

    
    if((lastAppVersion !== "" && this.appVersion.includes(lastAppVersion)) || this.appVersion == "" ) {
      return
    }

    this.$bvModal.show(this.$id('whatsnew'))
      //update the appVersion string in the cookie to the new version
      document.cookie = "lastAppVersion="+this.appVersion+";path='/';max-age=31536000"

  }
  
};
</script>
